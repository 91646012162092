import React, {Component} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import Login from "./components/accounting/Login";
import Registration from "./components/accounting/Registration";
import AccountSettings from "./components/accounting/AccountSettings";
import ProjectView from "./components/project/ProjectView";
import history from './helpers/history';
import {PrivateRoute} from "./helpers/PrivateRoute";
import ProjectSettingsView from "./components/project/projectSettings/ProjectSettingsView";
import ProductBacklogView from "./components/stateContainer/productBacklog/ProductBacklogView";

class App extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path={'/'} component={Login}/>
                    <Route path={'/registration'} component={Registration}/>
                    <PrivateRoute path={'/account'} component={AccountSettings}/>
                    <PrivateRoute exact path={'/project'} component={ProjectView}/>
                    <PrivateRoute path={'/project/:projectId/settings'} component={ProjectSettingsView}/>
                    <PrivateRoute path={'/project/new'} component={() => <ProjectSettingsView isCreate={true}/>}/>
                    <PrivateRoute path={'/project/:projectId'} component={ProductBacklogView}/>
                </Switch>
            </Router>
        );
    }
}

export default (App)