import axios from "axios";
import constants, {JWT_LOCAL_STORAGE, USER_LOCAL_STORAGE} from "../../constants";
import {
    GET_USER_ERROR,
    GET_USER_PENDING,
    GET_USER_SUCCESS,
    LOGIN_SUBMIT_ERROR,
    LOGIN_SUBMIT_PENDING,
    LOGIN_SUBMIT_SUCCESS,
    LOGOUT,
    REGISTRATION_SUBMIT_ERROR,
    REGISTRATION_SUBMIT_PENDING,
    REGISTRATION_SUBMIT_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_PENDING,
    UPDATE_USER_SUCCESS
} from "./user.types";
import makeRequest from "helpers/makeRequest";
import {authHeader} from "../../helpers/auth-header";
import history from "../../helpers/history";

export const sendUserRegistration = (values) => {
    delete values.confirmPassword

    return (dispatch) => {
        dispatch(sendUserRegistrationPending(values));
        let userPromise = axios.post(constants().apiUrl + '/register/', values)
        userPromise.then(
            res => {
                if (res.status === 200) {
                    dispatch(sendUserRegistrationSuccess());
                    history.push('/')
                }
            }
        ).catch(function (err) {
            dispatch(sendUserRegistrationError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const sendUserLogin = (values) => {
    return async (dispatch) => {
        dispatch(sendUserLoginPending(values));
        let res = await makeRequest("post", '/login/', values)

        if (res.status === 200) {
            localStorage.setItem(JWT_LOCAL_STORAGE, res.data.token);
            localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(res.data.user));
            dispatch(sendUserLoginSuccess(res.data.user));
            history.push('/project')
        } else {
            dispatch(sendUserLoginError(res.message))
            console.log('ERROR: ', res.message)
        }
    }
};

export const fetchUser = () => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchUserRequest());
        let userPromise = axios.get(constants().apiUrl + '/user/' + JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE)).userId, requestConfig)
        userPromise.then(
            res => {
                if (res.status === 401) {
                    logoutUser()
                    console.log('ERROR: ', res.message)

                } else {
                    dispatch(getUserSuccess(res.data.user))

                }
            }
        )
    }
}

export const updateUserData = (values) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(updateUserDataPending());
        let userPromise = axios.put(constants().apiUrl + '/user/' + JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE)).userId, values, requestConfig)
        userPromise.then(
            res => {
                localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(res.data.user));
                dispatch(updateUserDataSuccess(res.data.user))
            }
        ).catch(function (err) {
            dispatch(updateUserDataError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const logoutUser = () => ({
    type: LOGOUT,
});


export const fetchUserRequest = () => ({
    type: GET_USER_PENDING,
});

export const getUserSuccess = user => ({
    type: GET_USER_SUCCESS,
    payload: {user}
});

export const getUserError = err => ({
    type: GET_USER_ERROR,
    payload: {err}
});

export const sendUserLoginPending = (values) => ({
    type: LOGIN_SUBMIT_PENDING,
    payload: values
});

export const sendUserLoginError = (values) => ({
    type: LOGIN_SUBMIT_ERROR,
    payload: values
});

export const sendUserLoginSuccess = (values) => ({
    type: LOGIN_SUBMIT_SUCCESS,
    payload: values
});

export const sendUserRegistrationPending = (values) => ({
    type: REGISTRATION_SUBMIT_PENDING,
    payload: values
});

export const sendUserRegistrationSuccess = (values) => ({
    type: REGISTRATION_SUBMIT_SUCCESS,
    payload: values
});

export const sendUserRegistrationError = err => ({
    type: REGISTRATION_SUBMIT_ERROR,
    payload: {err}
});

export const updateUserDataPending = user => ({
    type: UPDATE_USER_PENDING,
    payload: {user}
});

export const updateUserDataSuccess = user => ({
    type: UPDATE_USER_SUCCESS,
    payload: {user}
});

export const updateUserDataError = err => ({
    type: UPDATE_USER_ERROR,
    payload: {err}
});