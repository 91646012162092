import axios from "axios";
import {
    ADD_USERROLES_ERROR,
    ADD_USERROLES_PENDING,
    ADD_USERROLES_SUCCESS,
    CLEAN_CURRENT_USERROLES_PROJECT,
    DEL_USERROLES_ERROR,
    DEL_USERROLES_PENDING,
    DEL_USERROLES_SUCCESS,
    GET_USERROLES_ERROR,
    GET_USERROLES_PENDING,
    GET_USERROLES_SUCCESS,
    UPDATE_USERROLES_ERROR,
    UPDATE_USERROLES_PENDING,
    UPDATE_USERROLES_SUCCESS
} from './userRoles.types'
import constants from '../../constants/index'
import {authHeader} from "helpers/auth-header";

export const fetchUserRoles = (projectId) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchUserRolesRequest());
        let userRolesPromise = axios.get(constants().apiUrl + '/project/' + projectId + '/userrole', requestConfig)
        userRolesPromise.then(
            res => {
                dispatch(getAllUserRolesSuccess(res.data.userRoles))
            }
        ).catch(function (err) {
            dispatch(getAllUserRolesError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchUserRolesRequest = () => ({
    type: GET_USERROLES_PENDING,
});

export const getAllUserRolesSuccess = userRoles => ({
    type: GET_USERROLES_SUCCESS,
    payload: {userRoles}
});

export const getAllUserRolesError = err => ({
    type: GET_USERROLES_ERROR,
    payload: err
});

export const addUserRole = (projectId, values) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(addUserRoleRequest());
        let userRolesPromise = axios.post(constants().apiUrl + '/project/' + projectId + '/userrole', values, requestConfig)
        userRolesPromise.then(
            res => {
                dispatch(addUserRoleSuccess(res.data.userRole))
            }
        ).catch(function (err) {
            dispatch(addUserRoleError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const addUserRoleRequest = () => ({
    type: ADD_USERROLES_PENDING,
});

export const addUserRoleSuccess = userRole => ({
    type: ADD_USERROLES_SUCCESS,
    payload: userRole
});

export const addUserRoleError = err => ({
    type: ADD_USERROLES_ERROR,
    payload: err
});


export const delUserRole = (projectId, userRoleId) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(delUserRoleRequest());
        let userRolesPromise = axios.delete(constants().apiUrl + '/project/' + projectId + '/userrole/' + userRoleId, requestConfig)
        userRolesPromise.then(
            res => {
                dispatch(delUserRoleSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(delUserRoleError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const delUserRoleRequest = () => ({
    type: DEL_USERROLES_PENDING,
});

export const delUserRoleSuccess = data => ({
    type: DEL_USERROLES_SUCCESS,
    payload: data
});

export const delUserRoleError = err => ({
    type: DEL_USERROLES_ERROR,
    payload: err
});

export const updateUserRole = (projectId, userRoleId, values) => {
    const requestConfig = {
        headers: authHeader()
    };

    let valuesNew = {...values};
    if (valuesNew.userRoleId) {
        delete valuesNew.userRoleId
    }

    return (dispatch) => {
        dispatch(updateUserRoleRequest());
        let userRolesPromise = axios.put(constants().apiUrl + '/project/' + projectId + '/userrole/' + userRoleId, valuesNew, requestConfig)
        userRolesPromise.then(
            res => {
                dispatch(updateUserRoleSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(updateUserRoleError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const updateUserRoleRequest = () => ({
    type: UPDATE_USERROLES_PENDING,
});

export const updateUserRoleSuccess = data => ({
    type: UPDATE_USERROLES_SUCCESS,
    payload: data
});

export const updateUserRoleError = err => ({
    type: UPDATE_USERROLES_ERROR,
    payload: err
});

export const cleanUserRoles = () => ({
    type: CLEAN_CURRENT_USERROLES_PROJECT
})