export const GET_COLLABORATORS_SUCCESS = 'GET_COLLABORATORS_SUCCESS';
export const GET_COLLABORATORS_PENDING = 'GET_COLLABORATORS_PENDING';
export const GET_COLLABORATORS_ERROR = 'GET_COLLABORATORS_ERROR';

export const ADD_COLLABORATORS_SUCCESS = 'ADD_COLLABORATORS_SUCCESS';
export const ADD_COLLABORATORS_PENDING = 'ADD_COLLABORATORS_PENDING';
export const ADD_COLLABORATORS_ERROR = 'ADD_COLLABORATORS_ERROR';

export const DEL_COLLABORATORS_SUCCESS = 'DEL_COLLABORATORS_SUCCESS';
export const DEL_COLLABORATORS_PENDING = 'DEL_COLLABORATORS_PENDING';
export const DEL_COLLABORATORS_ERROR = 'DEL_COLLABORATORS_ERROR';

export const UPDATE_COLLABORATORS_SUCCESS = 'UPDATE_COLLABORATORS_SUCCESS';
export const UPDATE_COLLABORATORS_PENDING = 'UPDATE_COLLABORATORS_PENDING';
export const UPDATE_COLLABORATORS_ERROR = 'UPDATE_COLLABORATORS_ERROR';

export const CLEAN_CURRENT_COLLABORATORS_PROJECT = 'CLEAN_CURRENT_COLLABORATORS_PROJECT';