import React, {useState} from 'react';
import {Button, Card, Modal} from "react-bootstrap";
import {Pencil, X} from 'react-bootstrap-icons'
import './ProjectCard.css'
import history from "../../helpers/history";
import store from "../../store";
import {deleteProject} from "model/projects/project.actions";

const GotTo = (props, path) => {
    history.push({pathname: path, state: props})
}

function ProjectCard(props) {
    const [show, setShow] = useState(false);
    return (
        <>
            <Card className={'cardStyle projectCard'} style={{backgroundColor: `${props.color}`}}>
                {props.typeOfProject === 'own' &&
                <>
                    <Button className={'buttonIconStyleProject'} onClick={() => GotTo(props, `${props.path}/settings`)}>
                        <Pencil id={'iconPencil'} size={18}/>
                    </Button>
                    <Button className={'buttonIconStyleProject'} onClick={() => setShow(true)}>
                        <X id={'iconX'} size={30}/>
                    </Button>
                </>
                }

                <Card.Body id={'openProject'} onClick={() => GotTo(props, props.path)}>
                    {props.typeOfProject === 'own' &&
                    <Card.Title id={'cardTitleProjectOwn'}>{props.title}</Card.Title>
                    }
                    {props.typeOfProject === 'invited' &&
                    <Card.Title id={'cardTitleProjectInvited'}>{props.title}</Card.Title>
                    }
                    <Card.Subtitle id={'descriptionTitleProject'}>Description</Card.Subtitle>
                    <Card.Text>
                        <div id={'descriptionProject'}>{props.description}</div>
                    </Card.Text>
                </Card.Body>
                <div id={'startDateProject'}>{props.startDate}</div>
            </Card>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header><b>Delete Project {props.title}</b></Modal.Header>
                <Modal.Body>
                    If you proceed, all the userstories, sprints, states etc. will be permanently deleted.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setShow(false);
                        store.dispatch(deleteProject(props.path));
                    }} variant="danger">
                        Delete project
                    </Button>
                    <Button onClick={() => setShow(false)} variant="outline-danger">
                        Abort
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProjectCard