import axios from "axios";
import constants from "../../constants";
import {
    CLEAN_CURRENT_GENERAL_SETTINGS_PROJECT,
    UPDATE_GENERAL_PENDING,
    UPDATE_GENERAL_SUCCESS,
    UPDATE_GENERAL_ERROR,
    GET_GENERAL_PENDING,
    GET_GENERAL_SUCCESS,
    GET_GENERAL_ERROR
} from "model/generalProjectSettings/generalProjectSettings.types";
import {authHeader} from "helpers/auth-header";
import DateFormatter from "helpers/dateFormatter";

export const updateGeneralSettings = (projectId, values) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        values.startDate = DateFormatter(values.startDate)
        dispatch(updateGeneralSettingsRequest());
        let userProjectPromise = axios.put(constants().apiUrl + '/project/' + projectId, values, requestConfig)
        userProjectPromise.then(
            res => {
                dispatch(updateGeneralSettingsSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(updateGeneralSettingsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const updateGeneralSettingsRequest = (values) => ({
    type: UPDATE_GENERAL_PENDING,
    payload: values
})

export const updateGeneralSettingsSuccess = (res) => ({
    type: UPDATE_GENERAL_SUCCESS,
    payload: res.project
})

export const updateGeneralSettingsError = (err) => ({
    type: UPDATE_GENERAL_ERROR,
    payload: err
})

export const fetchGeneralSettings = (projectId) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(getGeneralSettingsRequest());
        let userProjectPromise = axios.get(constants().apiUrl + '/project/' + projectId, requestConfig)
        userProjectPromise.then(
            res => {
                dispatch(getGeneralSettingsSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(getGeneralSettingsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const getGeneralSettingsRequest = (values) => ({
    type: GET_GENERAL_PENDING
})

export const getGeneralSettingsSuccess = (res) => ({
    type: GET_GENERAL_SUCCESS,
    payload: res.project
})

export const getGeneralSettingsError = (err) => ({
    type: GET_GENERAL_ERROR,
    payload: err
})

export const cleanGeneralSettings = () => ({
    type: CLEAN_CURRENT_GENERAL_SETTINGS_PROJECT
})