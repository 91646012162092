import {
    CLOSE_WEBSOCKETSERVER,
    CONNECT_WEBSOCKETSERVER,
    CONNECTED_WEBSOCKETSERVER,
    ERROR_WEBSOCKETSERVER,
    UPDATE_SOCIAL_INFORMATION
} from "model/webSocketReceiver/webSocketReceiver.types";

const INITIAL_STATE = {
    connected: false,
    connecting: true,
    social: {},
    url: null,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONNECT_WEBSOCKETSERVER:
            return ({
                ...state,
                connecting: true,
                connected: false
            })
        case CONNECTED_WEBSOCKETSERVER:
            return ({
                ...state,
                connecting: false,
                connected: true,
                url: action.payload.url
            })
        case ERROR_WEBSOCKETSERVER:
            return ({
                ...state,
                connecting: true,
                connected: false,
                url: null,
                error: action.payload,
                social: {}
            })
        case CLOSE_WEBSOCKETSERVER:
            return ({
                ...state,
                connecting: false,
                connected: false,
                url: null,
                social: {}
            })
        case UPDATE_SOCIAL_INFORMATION:
            return ({
                ...state,
                social: action.payload.data
            })

        default:
            return state;
    }
};

export default reducer;