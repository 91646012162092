import React, {useEffect} from "react"
import {connect} from "react-redux"

import FormCard from "components/form/formCard/FormCard";
import ListGroup from "react-bootstrap/ListGroup";
import UserRoleForm from "components/project/projectSettings/projectSettingsCards/userRoleSettingsCard/UserRoleForm";
import {PROJECT_SETTINGS_USERROLE_CREATE, PROJECT_SETTINGS_USERROLE_EDIT} from "constants/index";
import {cleanUserRoles, fetchUserRoles} from "model/userRoles/userRoles.actions";

import "./UserRoleSettingsCard.css"

let projectId;

function UserRoleSettingsCard({handleSubmit, mode, ...props}) {
    projectId = props.projectId;

    useEffect(() => {
        props.fetchUserRoles(projectId)
        return props.cleanUserRoles
    }, [])

    return (
        <FormCard title="User Roles" width="30vw" error={props.err} loading={props.loading}>
            <ListGroup class="list-group list-group-flush">
                {props.userRoles.map((userRole) => {
                    return (
                        <ListGroup.Item key={userRole.userRoleId}>
                            <UserRoleForm form={"userRoleForm" + userRole.userRoleId}
                                          mode={PROJECT_SETTINGS_USERROLE_EDIT} initialValues={userRole}
                                          projectId={projectId}/>
                        </ListGroup.Item>
                    )
                })}
                <ListGroup.Item key={99999} className="newSprint">
                    <UserRoleForm form="newUserRole" mode={PROJECT_SETTINGS_USERROLE_CREATE} projectId={projectId}/>
                </ListGroup.Item>
            </ListGroup>
        </FormCard>
    )
}

const mapStateToProps = state => {
    return {
        userRoles: state.projects.current.userRoles.userRolesList,
        loading: state.projects.current.userRoles.pending,
        err: state.projects.current.userRoles.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserRoles: (projectId) => dispatch(fetchUserRoles(projectId)),
        cleanUserRoles: () => dispatch(cleanUserRoles())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleSettingsCard)