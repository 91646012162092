import axios from "axios"
import constants from "constants/index";

const options = {
    baseURL: constants().apiUrl,
    responseType: "application/json"
};

export default async function makeRequest(requestType, route, body, sendJWT) {
    let res;
    switch (requestType) {
        case "post": {
            res = await axios.post(route, body, options).catch((err) => err);
            break
        }
        case "get": {
            res = await axios.get(route, body, options).catch((err) => err);
            break
        }
        case "put": {
            res = await axios.put(route, body, options).catch((err) => err);
            break
        }
        case "delete": {
            res = await axios.delete(route, body, options).catch((err) => err);
            break
        }
        default: {
            console.log("request error: no request type")
        }
    }
    return res;
}

