import React from "react";
import {Field, Form, reduxForm, reset} from "redux-form";
import store from "store";

import './StateSettingsCard.css'

import FieldInput from "components/form/fieldInput/FieldInput";
import {maxLength100, minLength3, required} from "validation/InputValidation";
import Button from "components/form/buttons/SubmitButton";
import {PROJECT_SETTINGS_STATE_CREATE, PROJECT_SETTINGS_STATE_EDIT} from "constants/index"
import {Trash} from "react-bootstrap-icons";
import {addState, delState, updateState} from "model/states/states.actions";


function StateForm({handleSubmit, mode, ...props}) {
    let projectId = props.projectId
    return (
        <>
            <Form id="stateSettings" onSubmit={handleSubmit}>
                <Field name="name"
                       label="Name"
                       type="text"
                       component={FieldInput}
                       placeholder="Name"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       validate={[required, minLength3, maxLength100]}
                />
                <Button
                    type="submit"
                    styleClass={mode === PROJECT_SETTINGS_STATE_CREATE ? "btn-primary-custom-btn-primary" : "btn-primary-custom-btn-submit"}
                    name={mode === PROJECT_SETTINGS_STATE_CREATE ? "Create state" : "Edit state"}
                />
            </Form>
            {mode === PROJECT_SETTINGS_STATE_EDIT &&
            <Button
                type="button"
                styleClass="btn-primary-custom-btn-naked deleteStateButton"
                name={<Trash/>}
                onClick={() => onDelete(projectId, props.initialValues.stateId)}
            />
            }
        </>
    )
}

const onSubmit = (values, dispatch, props) => {
    let stateId = props.initialValues ? props.initialValues.stateId : null
    let projectId = props.projectId
    if (!values.stateId) {
        store.dispatch(addState(projectId, values))
        store.dispatch(reset('newState'));
    } else {
        store.dispatch(updateState(projectId, stateId, values))
    }
}

const onDelete = (projectId, stateId) => {
    store.dispatch(delState(projectId, stateId))
}

StateForm = reduxForm({
    onSubmit
})(StateForm)

export default StateForm