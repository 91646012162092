import interact from 'interactjs'

const ID_OF_PRODUCT_BACKLOG = "productBacklog"

const Movement = () => {
    interact('.usCard')
        .draggable({
            startAxis: 'x',
            modifiers: [
                interact.modifiers.restrict({
                    elementRect: {top: 0, left: 0, bottom: 1, right: 1},
                    endOnly: true
                })
            ],
            listeners: {
                start(event) {
                    event.preventDefault()
                    const target = event.target;

                    let offsetY = target.getBoundingClientRect().y - target.parentElement.getBoundingClientRect().y

                    if(target.offsetParent.id !== ID_OF_PRODUCT_BACKLOG){
                        offsetY = offsetY - window.scrollY
                    }

                    target.style.position = 'fixed';
                    target.classList.add('drag-start');
                    target.style.zIndex = '10000';

                    // Calculate offset to parent
                    const offsetX = target.getBoundingClientRect().x - target.parentElement.getBoundingClientRect().x

                    target.setAttribute('data-x', -offsetX);
                    target.setAttribute('data-y', offsetY);
                },
                move(event) {
                    event.preventDefault()
                    const target = event.target;

                    const dataX = target.getAttribute('data-x');
                    const dataY = target.getAttribute('data-y');
                    const initialX = parseFloat(dataX) || 0;
                    const initialY = parseFloat(dataY) || 0;

                    const deltaX = event.dx;
                    const deltaY = event.dy;

                    const newX = initialX + deltaX;
                    const newY = initialY + deltaY;

                    target.style.transform = `translate(${newX}px, ${newY}px)`;

                    target.setAttribute('data-x', newX);
                    target.setAttribute('data-y', newY);
                },
                end(event) {
                    const target = event.target;

                    target.style.position = 'relative';
                    target.style.transform = `translate(0px, 0px)`;
                    target.classList.remove('drag-start');
                    target.style.zIndex = '0'
                }
            }
        })
    return null
}


export default Movement