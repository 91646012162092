import USCard from "components/USCard";
import React from "react";
import store from "store";
import {delUserStoryRequest, setCurrentUserStory} from "model/userStories/userStories.actions";
import {getUserStoriesBySprintState} from 'model/userStories/userStories.selector';
import {InitDropZone} from "components/interaction/Dropzone";
import './ProductBacklog.css'
import {connect} from "react-redux";
import Button from "../../form/buttons/SubmitButton";

class ProductBacklog extends React.Component {
    constructor({userStoryList, openModalCreate, openModalEdit, ...props}) {
        super({userStoryList, openModalCreate, openModalEdit, props});
    }

    createUserStoryModal() {
        this.props.openModalCreate()
    }

    editUserStoryModal(userStory) {
        this.props.openModalEdit()
        store.dispatch(setCurrentUserStory(userStory))
    }

    deleteUserStory(userStory) {
        store.dispatch(delUserStoryRequest(userStory, this.props.projectId))
    }

    componentDidMount() {
        InitDropZone("dropZoneBacklog", null, null, this.props.projectId);
        window.addEventListener("scroll", this.onScroll);
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        if (window.scrollY >= 150) {
            let dropzone = document.getElementsByClassName("productBacklog")[0]
            dropzone.classList.add("backlog-open")
        }else{
            let dropzone = document.getElementsByClassName("productBacklog")[0]
            dropzone.classList.remove("backlog-open")
        }
    }

    render() {
        return (
            <div className="productBacklog" id="productBacklog">
                <p>Product Backlog</p>
                <Button
                    styleClass={'btn-primary-custom-btn-addUUserStory'}
                    name="Add userstory"
                    onClick={() => this.createUserStoryModal()}
                />
                <div id="dropZoneBacklog" className="dropzone">
                    {
                        this.props.userStories.map((item) => {
                            return <USCard class={"card"}
                                           key={item.userStoryId}
                                           id={item.userStoryId}
                                           userStory={item}
                                           editLink={this.editUserStoryModal.bind(this)}
                                           deleteLink={this.deleteUserStory.bind(this)}
                                           style={{order: item.userStoryId}}
                            />
                        })
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userStories: getUserStoriesBySprintState(state.projects.current, null, null)
    }
}

export default connect(mapStateToProps)(ProductBacklog);