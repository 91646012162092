import {
    CLOSE_WEBSOCKETSERVER,
    CONNECT_WEBSOCKETSERVER,
    CONNECTED_WEBSOCKETSERVER,
    ERROR_WEBSOCKETSERVER,
    UPDATE_SOCIAL_INFORMATION
} from "model/webSocketReceiver/webSocketReceiver.types";

export const connectToWebSocketServer = () => ({
    type: CONNECT_WEBSOCKETSERVER,
});

export const closeConnectionToWebSocketServer = () => ({
    type: CLOSE_WEBSOCKETSERVER,
});

export const connectedToWebSocketServer = url => ({
    type: CONNECTED_WEBSOCKETSERVER,
    payload: {url: url}
});

export const updateSocialInformation = data => ({
    type: UPDATE_SOCIAL_INFORMATION,
    payload: {data}
});

export const errorConnectWebSocketServer = err => ({
    type: ERROR_WEBSOCKETSERVER,
    payload: err
});