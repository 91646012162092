export const GET_USERROLES_SUCCESS = 'GET_USERROLES_SUCCESS';
export const GET_USERROLES_PENDING = 'GET_USERROLES_PENDING';
export const GET_USERROLES_ERROR = 'GET_USERROLES_ERROR';

export const DEL_USERROLES_SUCCESS = 'DEL_USERROLES_SUCCESS';
export const DEL_USERROLES_PENDING = 'DEL_USERROLES_PENDING';
export const DEL_USERROLES_ERROR = 'DEL_USERROLES_ERROR';

export const ADD_USERROLES_SUCCESS = 'ADD_USERROLES_SUCCESS';
export const ADD_USERROLES_PENDING = 'ADD_USERROLES_PENDING';
export const ADD_USERROLES_ERROR = 'ADD_USERROLES_ERROR';

export const UPDATE_USERROLES_SUCCESS = 'UPDATE_USERROLES_SUCCESS';
export const UPDATE_USERROLES_PENDING = 'UPDATE_USERROLES_PENDING';
export const UPDATE_USERROLES_ERROR = 'UPDATE_USERROLES_ERROR';

export const CLEAN_CURRENT_USERROLES_PROJECT = 'CLEAN_CURRENT_USERROLES_PROJECT';