import {
    ADD_STATES_ERROR,
    ADD_STATES_PENDING,
    ADD_STATES_SUCCESS,
    CLEAN_CURRENT_STATES_PROJECT,
    DEL_STATES_ERROR,
    DEL_STATES_PENDING,
    DEL_STATES_SUCCESS,
    GET_STATES_ERROR,
    GET_STATES_PENDING,
    GET_STATES_SUCCESS,
    UPDATE_STATES_ERROR,
    UPDATE_STATES_PENDING,
    UPDATE_STATES_SUCCESS
} from './states.types';


const INITIAL_STATE = {
    statesList: [],
    pending: false,
    current: null,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAN_CURRENT_STATES_PROJECT:
            return {
                ...state,
                statesList: [],
                pending: false,
                error: null
            }
        case GET_STATES_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case GET_STATES_SUCCESS:
            return {
                ...state,
                pending: false,
                statesList: action.payload.states
            };
        case GET_STATES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case ADD_STATES_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case ADD_STATES_SUCCESS:
            return {
                ...state,
                pending: false,
                statesList: [...state.statesList, action.payload]
            };
        case ADD_STATES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case DEL_STATES_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case DEL_STATES_SUCCESS:
            return {
                ...state,
                pending: false,
                statesList: state.statesList.filter(state =>
                    state.stateId !== action.payload.stateId
                )
            };
        case DEL_STATES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case UPDATE_STATES_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case UPDATE_STATES_SUCCESS:
            return {
                ...state,
                pending: false,
                statesList: state.statesList.map((item) => {
                    if (item.stateId === action.payload.state.stateId) {
                        return action.payload.state
                    }
                    return item
                })
            };
        case UPDATE_STATES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        default:
            return state;
    }
};

export default reducer;