import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal'
import TabModal from "components/userStory/Tabs";
import './modal.css'

class ModalUS extends Component {

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size={'xl'}
                aria-labelledby={'contained-modal-title-vcenter'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id={'contained-modal-title-vcenter'}>
                        {this.props.modalMode === 'edit' && "Edit "}
                        {this.props.modalMode === 'create' && "Create Userstory"}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className={'color-modal'}>
                    <TabModal modalMode={this.props.modalMode} closeModal={this.props.onHide}/>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ModalUS