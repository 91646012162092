import React, {useState} from "react";
import {Button, Collapse} from "react-bootstrap";
import {CaretDown, CaretUp} from 'react-bootstrap-icons';
import './ListOfCheckboxes.css'
import {Field} from "redux-form";
import CheckboxInput from "../form/checkboxInput/CheckboxInput";

const ListOfCheckboxes = ({options, name, title, input, ...props}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button className={'listCheckboxButton'}
                    onClick={() => setOpen(!open)} aria-controls="collapse-listOfCheckboxes"
                    aria-expanded={open}>{title} {open === false &&
            <CaretDown className={'caret'}/>} {open === true && <CaretUp className={'caret'}/>}
            </Button>

            <Collapse in={open}>
                <div id="collapse-listOfCheckboxes">
                    <div className="listElements">
                        {options && options.map(item =>
                            <Field
                                type="checkbox"
                                name={name}
                                component={CheckboxInput}
                                item={item}
                            />
                        )}
                    </div>
                </div>
            </Collapse>
        </>
    )
}

export default ListOfCheckboxes