import React, {useEffect, useState} from "react"
import ListGroup from 'react-bootstrap/ListGroup'
import {connect} from 'react-redux'

import "./SprintSettingsCard.css"

import FormCard from "components/form/formCard/FormCard";
import SprintForm from "./SprintForm";
import {PROJECT_SETTINGS_SPRINT_CREATE, PROJECT_SETTINGS_SPRINT_EDIT} from "constants/index"
import DateFormatter from "helpers/dateFormatter";
import {cleanSprints, fetchSprints} from "model/sprints/sprints.actions"

function SprintSettingsCard({handleSubmit, mode, ...props}) {
    let projectId = props.projectId
    const mutateDateOnSprints = (sprints) => {
        sprints.forEach((sprint) => {
            sprint.startDate = DateFormatter(sprint.startDate)
            sprint.endDate = DateFormatter(sprint.endDate)
        })
        return sprints;
    }

    useEffect(() => {
        props.fetchSprints(projectId)
        return props.cleanSprints
    }, [])

    return (
        <FormCard title="sprints" width="30vw" error={props.err} loading={props.loading}>
            <ListGroup class="list-group list-group-flush">
                {mutateDateOnSprints(props.sprints).map((sprint) => {
                    return (
                        <ListGroup.Item key={sprint.sprintId}>
                            <SprintForm form={"sprintForm" + sprint.sprintId} mode={PROJECT_SETTINGS_SPRINT_EDIT}
                                        initialValues={sprint} projectId={projectId}/>
                        </ListGroup.Item>
                    )
                })}
                <ListGroup.Item className="newSprint">
                    <SprintForm form="newSprint" mode={PROJECT_SETTINGS_SPRINT_CREATE} projectId={projectId}/>
                </ListGroup.Item>
            </ListGroup>
        </FormCard>
    )

}

const mapStateToProps = state => {
    return {
        sprints: state.projects.current.sprints.sprintsList,
        loading: state.projects.current.sprints.pending,
        err: state.projects.current.sprints.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSprints: (projectId) => dispatch(fetchSprints(projectId)),
        cleanSprints: () => dispatch(cleanSprints())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SprintSettingsCard)