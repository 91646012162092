export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_PENDING = 'GET_STATES_PENDING';
export const GET_STATES_ERROR = 'GET_STATES_ERROR';

export const ADD_STATES_SUCCESS = 'ADD_STATES_SUCCESS';
export const ADD_STATES_PENDING = 'ADD_STATES_PENDING';
export const ADD_STATES_ERROR = 'ADD_STATES_ERROR';

export const DEL_STATES_SUCCESS = 'DEL_STATES_SUCCESS';
export const DEL_STATES_PENDING = 'DEL_STATES_PENDING';
export const DEL_STATES_ERROR = 'DEL_STATES_ERROR';

export const UPDATE_STATES_SUCCESS = 'UPDATE_STATES_SUCCESS';
export const UPDATE_STATES_PENDING = 'UPDATE_STATES_PENDING';
export const UPDATE_STATES_ERROR = 'UPDATE_STATES_ERROR';

export const CLEAN_CURRENT_STATES_PROJECT = 'CLEAN_CURRENT_STATES_PROJECT';