function RandomColor() {
    const randomNumber = Math.floor((Math.random() * 30) + 1);
    let color = '#00C78C'
    const colors = ['#00C78C', '#00FA9A', '#00EE76', '#698B22', '#00CD66', '#008B45', '#3CB371', '#4EEE94', '#43CD80', '#00C957', '#BDFCC9', '#3D9140', '#8FBC8F', '#C1FFC1', '#9BCD9B', '#98FB98', '#7CCD7C', '#32CD32', '#228B22', '#00FF00', '#00EE00', '#00CD00', '#008B00', '#7FFF00', '#66CD00', '#CAFF70', '#A2CD5A', '#6E8B3D', '#C0FF3E', '#9ACD32']
    colors.map((col, index) => {
        if (randomNumber === index) {
            color = col
        }
        return null
    });
    return color
}

export default RandomColor