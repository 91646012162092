import React, {useEffect} from "react"
import {connect} from 'react-redux'

import FormCard from "components/form/formCard/FormCard";
import ListGroup from "react-bootstrap/ListGroup";
import ThemeForm from "components/project/projectSettings/projectSettingsCards/themeSettingsCard/ThemeForm";
import {PROJECT_SETTINGS_THEME_CREATE, PROJECT_SETTINGS_THEME_EDIT} from "constants/index";
import {cleanThemes, fetchThemes} from "model/themes/themes.actions";

function ThemeSettingsCard({handleSubmit, mode, ...props}) {
    const INITIAL_THEME_COLOR = "123456";
    let projectId = props.projectId

    useEffect(() => {
        props.fetchThemes(projectId)
        return props.cleanThemes
    }, [])

    return (
        <FormCard title="themes" width="30vw" error={props.err} loading={props.loading}>
            <ListGroup class="list-group list-group-flush">
                {props.themes.map((theme) => {
                    return (
                        <ListGroup.Item key={theme.themeId}>
                            <ThemeForm form={"themeForm" + theme.themeId} mode={PROJECT_SETTINGS_THEME_EDIT}
                                       initialValues={theme} projectId={projectId}/>
                        </ListGroup.Item>
                    )
                })}
                <ListGroup.Item key={99999} className="newSprint">
                    <ThemeForm form="newTheme" mode={PROJECT_SETTINGS_THEME_CREATE}
                               initialValues={{color: INITIAL_THEME_COLOR}} projectId={projectId}/>
                </ListGroup.Item>
            </ListGroup>
        </FormCard>
    )
}

const mapStateToProps = state => {
    return {
        themes: state.projects.current.themes.themesList,
        loading: state.projects.current.themes.pending,
        err: state.projects.current.themes.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchThemes: (projectId) => dispatch(fetchThemes(projectId)),
        cleanThemes: () => dispatch(cleanThemes())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSettingsCard)