import DateFormatter from "../../helpers/dateFormatter";

export const getUserStoriesBySprintState = (state, sprintId, stateId) => {

    let userStoryList = state.userStories.userStoryList
    let filterCriteria = state.filterCriteria

    if (filterCriteria.themes.length > 0) {
        let themes = []
        filterCriteria.themes.map(option => {
            let values = userStoryList.filter(item => (item.themeName === option))
            themes = themes.concat(values)
        })
        userStoryList = themes
    }

    if (filterCriteria.userRoles.length > 0) {
        let userRoles = []
        filterCriteria.userRoles.map(option => {
            let values = userStoryList.filter(item => (item.userRoleName === option))
            userRoles = userRoles.concat(values)
        })
        userStoryList = userRoles
    }

    if (filterCriteria.collaborators.length > 0) {
        let collaborators = []
        filterCriteria.collaborators.map(option => {
            let values = userStoryList.filter(item => (item.firstname === option))
            collaborators = collaborators.concat(values)
        })
        userStoryList = collaborators
    }

    if (filterCriteria.priorities.length > 0) {
        let priorities = []
        filterCriteria.priorities.map(option => {
            let values = userStoryList.filter(item => (item.priority === option))
            priorities = priorities.concat(values)
        })
        userStoryList = priorities
    }

    if (filterCriteria.estimations.length > 0) {
        let estimations = []
        filterCriteria.estimations.map(option => {
            let values = userStoryList.filter(item => (item.estimate === option))
            estimations = estimations.concat(values)
        })
        userStoryList = estimations
    }

    if (filterCriteria.creationDates.length > 0) {
        let creationDates = []
        filterCriteria.creationDates.map(option => {
            let values = userStoryList.filter(item => (DateFormatter(item.creationDate) === option))
            creationDates = creationDates.concat(values)
        })
        userStoryList = creationDates
    }

    return userStoryList.filter(item => (item.stateId === stateId && item.sprintId === sprintId))
}

