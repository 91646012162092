import React from "react";
import {Spinner} from "react-bootstrap"

function Loader({absolute, size, props}) {
    return (
        <>
            {!absolute && <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Spinner animation="grow" size={size ? size : null}/>
            </div>}
            {absolute &&
            <Spinner animation="grow" size={size ? size : null}/>
            }
        </>
    )
}

export default Loader;