import React from "react";
import './Conversation.css'
import "../../form/buttons/button.css"
import {FormControl, InputGroup} from "react-bootstrap";
import Button from "../../form/buttons/SubmitButton";

function USConversation() {
    return (
        <div className="us_conversation">
            <label htmlFor="basic-url">More information</label>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Comment" as="textarea" aria-label="With textarea"/>
            </InputGroup>

            <Button name={'Add new comment'} styleClass={'btn-primary-custom-btn-primary'}/>
            <Button name={'Add response'} styleClass={'btn-primary-custom-btn-primary'}/>
        </div>
    )
}

export default USConversation

