import React from "react";
import Card from 'react-bootstrap/Card'
import './FormCard.css';
import Loader from "components/Loader";

function FormCard({title, width, height, loading, error, ...props}) {
    return (
        <Card style={{width: width, height: height}} {...props} id="formCard">
            <Card.Header>
                {title.toUpperCase()}
                <span className={!error?"info-panel":"info-panel-error"}>
                    {error}
                    {loading && <Loader absolute={true} size={"sm"}/>}
                </span>
            </Card.Header>
            <Card.Body>
                {props.children}
            </Card.Body>
        </Card>
    )
}

export default FormCard