import React from 'react';
import {Card} from "react-bootstrap";
import './AddProjectCard.css'
import {Plus} from "react-bootstrap-icons";
import history from "../../helpers/history";

const addProjectPage = () => {
    history.push('/project/new')
}

function AddProjectCard() {
    return (
        <Card as='a' onClick={addProjectPage} id={'cardStyleCreate'}>
            <Card.Body id={'cardBody'}>
                <Card.Title id={'cardTitle'}>Create New Project</Card.Title>
                <Plus size={110}/>
            </Card.Body>
        </Card>
    )
}

export default AddProjectCard