import React from "react";
import './Avatar.css';

function Avatar(props) {
    return (
        <div id={'circle'} style={{
            height: `${props.size}`,
            width: `${props.size}`,
            fontSize: `${props.fontsize}`,
            border: `${props.border}`
        }}>
            <div id={'text'}>{props.name}</div>
        </div>
    )
}

export default Avatar