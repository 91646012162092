/**
 * This combines all reducers
 */
import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form'

import userStoriesReducer from './userStories/userStories.reducer.js';
import themeReducer from './themes/themes.reducer';
import userRolesReducer from './userRoles/userRoles.reducer';
import stateReducer from './states/states.reducer';
import sprintReducer from './sprints/sprints.reducer';
import userReducer from './user/user.reducer';
import projectReducer from './projects/project.reducer'
import generalProjectSettingsReducer from './generalProjectSettings/generalProjectSettings.reducer'
import collaboratorReducer from './collaborators/collaborators.reducer'
import webSocketReducer from './webSocketReceiver/webSocketReceiver.reducer'
import filterCriteriaReducer from './filtering/filterCriteria.reducer'

const rootReducer = combineReducers({
    user: userReducer,
    websocket: webSocketReducer,
    projects: combineReducers({
        myProjects: projectReducer,
        current: combineReducers({
            generalProjectSettings: generalProjectSettingsReducer,
            userStories: userStoriesReducer,
            themes: themeReducer,
            userRoles: userRolesReducer,
            states: stateReducer,
            sprints: sprintReducer,
            collaborators: collaboratorReducer,
            filterCriteria: filterCriteriaReducer
        })
    }),
    form: formReducer
});

export default rootReducer;