import axios from "axios";
import {
    ADD_THEMES_ERROR,
    ADD_THEMES_PENDING,
    ADD_THEMES_SUCCESS,
    CLEAN_CURRENT_THEMES_PROJECT,
    DEL_THEMES_ERROR,
    DEL_THEMES_PENDING,
    DEL_THEMES_SUCCESS,
    GET_THEMES_ERROR,
    GET_THEMES_PENDING,
    GET_THEMES_SUCCESS,
    UPDATE_THEMES_ERROR,
    UPDATE_THEMES_PENDING,
    UPDATE_THEMES_SUCCESS
} from './themes.types'
import constants from '../../constants/index'
import {authHeader} from "helpers/auth-header";

export const fetchThemes = (projectId) => {
    const requestConfig = {
        headers: authHeader()
    };

    return (dispatch) => {
        dispatch(fetchThemesRequest());
        let themesPromise = axios.get(constants().apiUrl + '/project/' + projectId + '/theme', requestConfig)
        themesPromise.then(
            res => {
                dispatch(getAllThemesSuccess(res.data.themes))
            }
        ).catch(function (err) {
            dispatch(getAllThemesError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchThemesRequest = () => ({
    type: GET_THEMES_PENDING,
});

export const getAllThemesSuccess = themes => ({
    type: GET_THEMES_SUCCESS,
    payload: {themes}
});

export const getAllThemesError = err => ({
    type: GET_THEMES_ERROR,
    payload: err
});


export const addTheme = (projectId, values) => {
    const requestConfig = {
        headers: authHeader()
    };

    let newValues = {
        ...values,
        color: values.color.hex ? values.color.hex.substring(values.color.hex.length - 6) : values.color
    }

    return (dispatch) => {
        dispatch(addThemeRequest());
        let themesPromise = axios.post(constants().apiUrl + '/project/' + projectId + '/theme', newValues, requestConfig)
        themesPromise.then(
            res => {
                dispatch(addThemeSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(addThemeError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const addThemeRequest = () => ({
    type: ADD_THEMES_PENDING,
});

export const addThemeSuccess = data => ({
    type: ADD_THEMES_SUCCESS,
    payload: data
});

export const addThemeError = err => ({
    type: ADD_THEMES_ERROR,
    payload: err
});


export const delTheme = (projectId, themeId) => {
    const requestConfig = {
        headers: authHeader()
    };

    return (dispatch) => {
        dispatch(delThemeRequest());
        let themesPromise = axios.delete(constants().apiUrl + '/project/' + projectId + '/theme/' + themeId, requestConfig)
        themesPromise.then(
            res => {
                dispatch(delThemeSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(delThemeError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const delThemeRequest = () => ({
    type: DEL_THEMES_PENDING,
});

export const delThemeSuccess = data => ({
    type: DEL_THEMES_SUCCESS,
    payload: data
});

export const delThemeError = err => ({
    type: DEL_THEMES_ERROR,
    payload: err
});


export const updateTheme = (projectId, themeId, values) => {
    const requestConfig = {
        headers: authHeader()
    };

    let newValues = {
        ...values,
        themeId: undefined,
        color: values.color.hex ? values.color.hex.substring(values.color.hex.length - 6) : values.color
    }

    return (dispatch) => {
        dispatch(updateThemeRequest());
        let themesPromise = axios.put(constants().apiUrl + '/project/' + projectId + '/theme/' + themeId, newValues, requestConfig)
        themesPromise.then(
            res => {
                dispatch(updateThemeSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(updateThemeError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const updateThemeRequest = () => ({
    type: UPDATE_THEMES_PENDING,
});

export const updateThemeSuccess = data => ({
    type: UPDATE_THEMES_SUCCESS,
    payload: data
});

export const updateThemeError = err => ({
    type: UPDATE_THEMES_ERROR,
    payload: err
});

export const cleanThemes = () => ({
    type: CLEAN_CURRENT_THEMES_PROJECT
})