import {JWT_LOCAL_STORAGE} from "../constants";

export function authHeader() {
    // return authorization header with jwt token
    let token = localStorage.getItem(JWT_LOCAL_STORAGE);

    if (token) {
        return {'Authorization': 'Bearer ' + token};
    } else {
        return {};
    }
}
