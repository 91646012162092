import {
    ADD_THEMES_ERROR,
    ADD_THEMES_PENDING,
    ADD_THEMES_SUCCESS,
    CLEAN_CURRENT_THEMES_PROJECT,
    DEL_THEMES_ERROR,
    DEL_THEMES_PENDING,
    DEL_THEMES_SUCCESS,
    GET_THEMES_ERROR,
    GET_THEMES_PENDING,
    GET_THEMES_SUCCESS,
    UPDATE_THEMES_ERROR,
    UPDATE_THEMES_PENDING,
    UPDATE_THEMES_SUCCESS
} from './themes.types'


const INITIAL_STATE = {
    themesList: [],
    pending: false,
    current: null,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAN_CURRENT_THEMES_PROJECT:
            return {
                ...state,
                themesList: [],
                pending: false,
                error: null
            }
        case GET_THEMES_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_THEMES_SUCCESS:
            return {
                ...state,
                pending: false,
                themesList: action.payload.themes
            };
        case GET_THEMES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case ADD_THEMES_PENDING:
            return {
                ...state,
                pending: true
            };
        case ADD_THEMES_SUCCESS:
            return {
                ...state,
                pending: false,
                themesList: [...state.themesList, action.payload.theme]
            };
        case ADD_THEMES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case DEL_THEMES_PENDING:
            return {
                ...state,
                pending: true
            };
        case DEL_THEMES_SUCCESS:
            return {
                ...state,
                pending: false,
                themesList: state.themesList.filter(theme =>
                    theme.themeId !== action.payload.themeId
                )
            };
        case DEL_THEMES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case UPDATE_THEMES_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case UPDATE_THEMES_SUCCESS:
            return {
                ...state,
                pending: false,
                themesList: state.themesList.map((item) => {
                    if (item.themeId === action.payload.theme.themeId) {
                        return action.payload.theme
                    }
                    return item
                })
            };
        case UPDATE_THEMES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        default:
            return state;
    }
};

export default reducer;