const constants = () => {
    if (process.env.NODE_ENV === 'production') {
        return {
            apiUrl: "https://www.storyscreen.ch/api/v1/",
            websocketUrl: "https://www.storyscreen.ch:443",
            env: "prod"
        }
    } else {
        return {
            apiUrl: "http://localhost:8080",
            websocketUrl: "http://localhost:8080",
            env: "dev"
        }
    }
}

export const JWT_LOCAL_STORAGE = "JWT_LOCAL_STORAGE";
export const USER_LOCAL_STORAGE = "USER_LOCAL_STORAGE";

export const PROJECT_SETTINGS_EDIT = "PROJECT_SETTINGS_EDIT";
export const PROJECT_SETTINGS_CREATE = "PROJECT_SETTINGS_CREATE";
export const PROJECT_SETTINGS_SPRINT_CREATE = "PROJECT_SETTINGS_SPRINT_CREATE";
export const PROJECT_SETTINGS_SPRINT_EDIT = "PROJECT_SETTINGS_SPRINT_EDIT";
export const PROJECT_SETTINGS_STATE_CREATE = "PROJECT_SETTINGS_STATE_CREATE";
export const PROJECT_SETTINGS_STATE_EDIT = "PROJECT_SETTINGS_STATE_EDIT";
export const PROJECT_SETTINGS_USERROLE_CREATE = "PROJECT_SETTINGS_USERROLE_CREATE";
export const PROJECT_SETTINGS_USERROLE_EDIT = "PROJECT_SETTINGS_USERROLE_EDIT";
export const PROJECT_SETTINGS_THEME_CREATE = "PROJECT_SETTINGS_THEME_CREATE";
export const PROJECT_SETTINGS_THEME_EDIT = "PROJECT_SETTINGS_THEME_EDIT";

export default constants;