import React from 'react';
import logo_Login from '../../assets/Logo_PA_400x400.svg';
import './Login.css'
import {Spinner} from "react-bootstrap"
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import store from "store";
import Button from 'components/form/buttons/SubmitButton';
import FieldInput from "../form/fieldInput/FieldInput";
import {email, maxLength100, required} from "validation/InputValidation";
import {sendUserLogin} from "model/user/user.action"

const mapStateToProps = (state) => ({
    loading: state.user.pending,
    response: state.user.error
});

let Login = ({handleSubmit, ...props}) => {
    return (
        <div className={'container-login'}>
            <div id={'row1Login'}>
                <h1 className={'StoryScreenTitle'}>StoryScreen</h1>
                <img id={'imageLogin'} src={logo_Login} alt="Logo_PA_400x400"/>
            </div>
            <div id={'row2Login'}>
                <h1 className={'loginTitle'}>Login</h1>
                <div id={'inputFields'}>
                    <form onSubmit={handleSubmit}>
                        <Field id={'email'}
                               name="email"
                               label="E-Mail"
                               type="email"
                               fieldType='login'
                               component={FieldInput}
                               placeholder="example@gmail.com"
                               aria-label="Recipient's username"
                               aria-describedby="emailHelp"
                               validate={[required, maxLength100, email]}
                        />
                        <Field id={'password'}
                               name="password"
                               label="Password"
                               type="password"
                               fieldType='login'
                               component={FieldInput}
                               placeholder="Password"
                               aria-label="Recipient's username"
                               validate={[required, maxLength100]}
                        />
                        <div className={'buttonLogin'}>
                            <Button
                                type="submit"
                                styleClass="btn-primary-custom-btn-submit"
                                name="Login"
                            /></div>
                    </form>
                    {props.loading &&
                    <Spinner animation="grow"/>}
                    {props.response &&
                    <p id={'errorMessage'}>Incorrect email or password.</p>}
                </div>
                <Link id={'link'} to={'/projectView'}/>
                <p className={'loginText'}>Need an account?
                    <Link className={'loginLink'} to={'/registration'}> Sign Up</Link>
                </p>
            </div>
        </div>

    )
}

const onSubmit = values => {
    store.dispatch(sendUserLogin(values));
}

Login = reduxForm({
    form: 'login',
    onSubmit
})(Login)

export default connect(mapStateToProps)(Login)