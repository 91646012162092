import {
    ADD_SPRINTS_ERROR,
    ADD_SPRINTS_PENDING,
    ADD_SPRINTS_SUCCESS,
    CLEAN_CURRENT_SPRINTS_PROJECT,
    DEL_SPRINTS_ERROR,
    DEL_SPRINTS_PENDING,
    DEL_SPRINTS_SUCCESS,
    GET_SPRINTS_ERROR,
    GET_SPRINTS_PENDING,
    GET_SPRINTS_SUCCESS,
    UPDATE_SPRINTS_ERROR,
    UPDATE_SPRINTS_PENDING,
    UPDATE_SPRINTS_SUCCESS
} from './sprints.types'


const INITIAL_SPRINT = {
    sprintsList: [],
    pending: false,
    current: null,
    error: null
};

const reducer = (state = INITIAL_SPRINT, action) => {
    switch (action.type) {
        case CLEAN_CURRENT_SPRINTS_PROJECT:
            return {
                ...state,
                sprintsList: [],
                error: null,
                pending: false
            }
        case GET_SPRINTS_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_SPRINTS_SUCCESS:
            return {
                ...state,
                pending: false,
                sprintsList: action.payload.sprints
            };
        case GET_SPRINTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case ADD_SPRINTS_PENDING:
            return {
                ...state,
                pending: true
            };
        case ADD_SPRINTS_SUCCESS:
            return {
                ...state,
                pending: false,
                sprintsList: [...state.sprintsList, action.payload.sprint]
            };
        case ADD_SPRINTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case DEL_SPRINTS_PENDING:
            return {
                ...state,
                pending: true
            };
        case DEL_SPRINTS_SUCCESS:
            return {
                ...state,
                pending: false,
                sprintsList: state.sprintsList.filter(sprint =>
                    sprint.sprintId !== action.payload.sprintId
                )
            };
        case DEL_SPRINTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };

        case UPDATE_SPRINTS_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case UPDATE_SPRINTS_SUCCESS:
            return {
                ...state,
                pending: false,
                sprintsList: state.sprintsList.map((item) => {
                    if (item.sprintId === action.payload.sprint.sprintId) {
                        return action.payload.sprint
                    }
                    return item
                })
            };
        case UPDATE_SPRINTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        default:
            return state;
    }
};

export default reducer;