import React from "react";
import {Field, Form, reduxForm, reset} from "redux-form";
import {Trash} from "react-bootstrap-icons"

import FieldInput from "components/form/fieldInput/FieldInput";
import FieldColorPicker from "components/form/colorPicker/FieldColorPicker";
import {maxLength100, minLength3, required} from "validation/InputValidation";
import Button from "components/form/buttons/SubmitButton";
import store from "store";

import "./ThemeSettingsCard.css";
import {PROJECT_SETTINGS_THEME_CREATE, PROJECT_SETTINGS_THEME_EDIT} from "constants/index"

import {addTheme, delTheme, updateTheme} from "model/themes/themes.actions";

function ThemeForm({handleSubmit, mode, ...props}) {
    let projectId = props.projectId
    let themeId = props.initialValues ? props.initialValues.themeId : null
    return (
        <>
            <Form id="themeSettings" onSubmit={handleSubmit}>
                <Field name="name"
                       label="Name"
                       type="text"
                       component={FieldInput}
                       placeholder="Name"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       className="themeNameField"
                       validate={[required, minLength3, maxLength100]}
                       prepend={<Field name="color"
                                       className="themeColorField"
                                       component={FieldColorPicker}
                       />}
                />
                <Button
                    type="submit"
                    styleClass={mode === PROJECT_SETTINGS_THEME_CREATE ? "btn-primary-custom-btn-primary" : "btn-primary-custom-btn-submit"}
                    name={mode === PROJECT_SETTINGS_THEME_CREATE ? "Create theme" : "Edit theme"}
                />
            </Form>
            {mode === PROJECT_SETTINGS_THEME_EDIT &&
            <Button
                styleClass="btn-primary-custom-btn-naked deleteSprintButton"
                name={<Trash/>}
                onClick={() => onDelete(projectId, themeId)}
            />
            }
        </>
    )
}

const onSubmit = (values, dispatch, props) => {
    let themeId = props.initialValues ? props.initialValues.themeId : null
    let projectId = props.projectId
    if (!values.themeId) {
        store.dispatch(addTheme(projectId, values))
        store.dispatch(reset('newTheme'));
    } else {
        store.dispatch(updateTheme(projectId, themeId, values))
    }
}

const onDelete = (projectId, themeId) => {
    store.dispatch(delTheme(projectId, themeId))
}

ThemeForm = reduxForm({
    onSubmit
})(ThemeForm)

export default ThemeForm