import React from "react";
import './stateView.css'
import './state.css'
import State from "components/stateContainer/state/state";
import store from "../../../store";

let StateView = ({states, sprintName, userStories, sprintId, ...props}) => {
    const state = store.getState();
    const filterCriteria = state.projects.current.filterCriteria
    let filterStates = [...states]

    if(filterCriteria.states.length !== 0) {
        filterStates = filterStates.filter((item) => filterCriteria.states.includes(item.name))
    }
    return (
        <div className="stateView">
            {
                filterStates.map((item) => {
                    return <State
                        key={item.stateId}
                        projectId={props.projectId}
                        stateName={item.name}
                        stateId={item.stateId}
                        style={{order: item.stateNumber}}
                        sprintId={sprintId}
                        sprintName={sprintName}
                        openModalEdit={props.openModalEdit}
                    />
                })
            }
        </div>
    )
}

export default StateView;

