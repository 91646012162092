import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {JWT_LOCAL_STORAGE} from "../constants";

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        localStorage.getItem(JWT_LOCAL_STORAGE)
            ? <Component {...props} />
            : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    )}/>
)