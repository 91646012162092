import React, {useState} from 'react'
import reactCSS from 'reactcss'
import {CirclePicker} from 'react-color'

const FieldColorPicker = ({input, meta: {initial}, ...props}) => {
    const AVAILABLE_COLORS = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]

    const parseHexToRgb = (hex) => {
        let aRgbHex = hex.match(/.{1,2}/g);
        return {
            r: parseInt(aRgbHex[0], 16),
            g: parseInt(aRgbHex[1], 16),
            b: parseInt(aRgbHex[2], 16),
            a: 1
        };
    }

    const [color, setColor] = useState(initial ? parseHexToRgb(initial) : {
        r: 76,
        g: 175,
        b: 80,
        a: 1
    })
    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    const handleChange = (color) => {
        setDisplayColorPicker(false)
        setColor(color.rgb)
    };

    const styles = reactCSS({
        'default': {
            color: {
                width: '20px',
                height: '20px',
                borderRadius: '50px',
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                background: 'transparent',
                border: 'none',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                background: 'white',
                padding: '5px',
                border: '1px solid black',
                borderRadius: '5px',
                position: 'absolute',
                zIndex: '9999',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <>
            <div style={styles.swatch} onClick={handleClick} {...props}>
                <div style={styles.color}/>
            </div>
            {displayColorPicker ? <div style={styles.popover}>
                <div style={styles.cover} onClick={handleClose}/>
                <CirclePicker colors={AVAILABLE_COLORS} {...input} color={color} onChange={(e) => {
                    handleChange(e);
                    input.onChange(e)
                }} disableAlpha/>
            </div> : null}
        </>
    )
}


export default FieldColorPicker