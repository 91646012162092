import axios from "axios";
import store from "store";
import {
    ADD_STATES_ERROR,
    ADD_STATES_PENDING,
    ADD_STATES_SUCCESS,
    CLEAN_CURRENT_STATES_PROJECT,
    DEL_STATES_ERROR,
    DEL_STATES_PENDING,
    DEL_STATES_SUCCESS,
    GET_STATES_ERROR,
    GET_STATES_PENDING,
    GET_STATES_SUCCESS,
    UPDATE_STATES_ERROR,
    UPDATE_STATES_PENDING,
    UPDATE_STATES_SUCCESS
} from './states.types'
import constants from '../../constants/index'
import {authHeader} from "helpers/auth-header";

export const fetchStates = (projectId) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchStatesRequest());
        let statesPromise = axios.get(constants().apiUrl + '/project/' + projectId + '/state', requestConfig)
        statesPromise.then(
            res => {
                dispatch(getAllStatesSuccess(res.data.states))
            }
        ).catch(function (err) {
            dispatch(getAllStatesError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchStatesRequest = () => ({
    type: GET_STATES_PENDING,
});

export const getAllStatesSuccess = states => ({
    type: GET_STATES_SUCCESS,
    payload: {states}
});

export const getAllStatesError = err => ({
    type: GET_STATES_ERROR,
    payload: err
});

export const addState = (projectId, values) => {
    const requestConfig = {
        headers: authHeader()
    };
    values = {
        ...values,
        stateNumber: getNextBiggestStateNumberFromCurrent()
    }
    return (dispatch) => {
        dispatch(addStateRequest());
        let statesPromise = axios.post(constants().apiUrl + '/project/' + projectId + '/state', values, requestConfig)
        statesPromise.then(
            res => {
                dispatch(addStateSuccess(res.data.state))
            }
        ).catch(function (err) {
            dispatch(addStateError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const addStateRequest = () => ({
    type: ADD_STATES_PENDING
});

export const addStateSuccess = state => ({
    type: ADD_STATES_SUCCESS,
    payload: state
});

export const addStateError = err => ({
    type: ADD_STATES_ERROR,
    payload: err
});

export const delState = (projectId, stateId) => {
    const requestConfig = {
        headers: authHeader()
    };

    return (dispatch) => {
        dispatch(delStateRequest());
        let statesPromise = axios.delete(constants().apiUrl + '/project/' + projectId + '/state/' + stateId, requestConfig)
        statesPromise.then(
            res => {
                dispatch(delStateSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(delStateError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const delStateRequest = () => ({
    type: DEL_STATES_PENDING
});

export const delStateSuccess = data => ({
    type: DEL_STATES_SUCCESS,
    payload: data
});

export const delStateError = err => ({
    type: DEL_STATES_ERROR,
    payload: err
});

export const updateState = (projectId, stateId, values) => {
    const requestConfig = {
        headers: authHeader()
    };

    let valuesNew = {...values};
    if (valuesNew.stateId) {
        delete valuesNew.stateId
    }

    return (dispatch) => {
        dispatch(updateStateRequest());
        let statesPromise = axios.put(constants().apiUrl + '/project/' + projectId + '/state/' + stateId, valuesNew, requestConfig)
        statesPromise.then(
            res => {
                dispatch(updateStateSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(updateStateError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const updateStateRequest = () => ({
    type: UPDATE_STATES_PENDING
});

export const updateStateSuccess = data => ({
    type: UPDATE_STATES_SUCCESS,
    payload: data
});

export const updateStateError = err => ({
    type: UPDATE_STATES_ERROR,
    payload: err
});

export const cleanStates = () => ({
    type: CLEAN_CURRENT_STATES_PROJECT
})

const getNextBiggestStateNumberFromCurrent = () => {
    let state = store.getState();
    let biggestNumber = 0;
    state.projects.current.states.statesList.map(state => {
        if (state.stateNumber > biggestNumber) {
            biggestNumber = state.stateNumber
        }
    })
    return biggestNumber + 1
}