import {
    ADD_COLLABORATORS_ERROR,
    ADD_COLLABORATORS_PENDING,
    ADD_COLLABORATORS_SUCCESS,
    CLEAN_CURRENT_COLLABORATORS_PROJECT,
    DEL_COLLABORATORS_ERROR,
    DEL_COLLABORATORS_PENDING,
    DEL_COLLABORATORS_SUCCESS,
    GET_COLLABORATORS_ERROR,
    GET_COLLABORATORS_PENDING,
    GET_COLLABORATORS_SUCCESS,
    UPDATE_COLLABORATORS_ERROR,
    UPDATE_COLLABORATORS_PENDING,
    UPDATE_COLLABORATORS_SUCCESS
} from 'model/collaborators/collaborators.types';


const INITIAL_COLLABORATOR = {
    collaboratorsList: [],
    pending: false,
    current: null,
    error: null
};

const reducer = (state = INITIAL_COLLABORATOR, action) => {
    switch (action.type) {
        case CLEAN_CURRENT_COLLABORATORS_PROJECT:
            return {
                ...state,
                collaboratorsList: [],
                error: null,
                pending: false
            }
        case GET_COLLABORATORS_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case GET_COLLABORATORS_SUCCESS:
            return {
                ...state,
                pending: false,
                collaboratorsList: action.payload.collaborators
            };
        case GET_COLLABORATORS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case ADD_COLLABORATORS_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case ADD_COLLABORATORS_SUCCESS:
            return {
                ...state,
                pending: false,
                collaboratorsList: [...state.collaboratorsList, action.payload]
            };
        case ADD_COLLABORATORS_ERROR:
            console.log(action.payload)
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case DEL_COLLABORATORS_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case DEL_COLLABORATORS_SUCCESS:
            return {
                ...state,
                pending: false,
                collaboratorsList: state.collaboratorsList.filter(collaborator =>
                    collaborator.userId !== action.payload.userId
                )
            };
        case DEL_COLLABORATORS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case UPDATE_COLLABORATORS_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case UPDATE_COLLABORATORS_SUCCESS:
            return {
                ...state,
                pending: false,
                collaboratorsList: state.collaboratorsList.map((item) => {
                    if (item.collaboratorId === action.payload.collaborator.collaboratorId) {
                        return action.payload.collaborator
                    }
                    return item
                })
            };
        case UPDATE_COLLABORATORS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        default:
            return state;
    }
};

export default reducer;