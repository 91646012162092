import React, {useState} from "react";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import USInputGroup from "components/userStory/inputGroup/USInputGroup";
import USConversation from "components/userStory/conversation/USConversation";
import USConfirmation from "components/userStory/confirmation/USConfirmation";
import {useParams} from "react-router";

function TabModal(props) {
    const [key, setKey] = useState('story');
    const {projectId} = useParams()

    return (
        <Tabs
            defaultActiveKey={key}
            onSelect={(k) => setKey(k)}
        >
            <Tab eventKey='story' title="Story" >
                <USInputGroup modalMode={props.modalMode} closeModal={props.closeModal} projectId={projectId}/>
            </Tab>
            {props.modalMode === 'edit' &&
            <Tab eventKey='conversation' title="Conversation">
                <USConversation/>
            </Tab>}
            {props.modalMode === 'edit' &&
            <Tab eventKey='confirmation' title="Confirmation">
                <USConfirmation/>
            </Tab>}
        </Tabs>
    )
}

export default TabModal;