import React, {Component} from "react";
import store from "../../../store";
import {fetchUserStories, setCurrentUserStory} from "model/userStories/userStories.actions";
import NavBar from "../../navigation/NavBar";
import ProjectHeader from "../../navigation/ProjectHeader";
import ModalUS from "../../userStory/Modal";
import ProductBacklog from "./ProductBacklog";
import SprintView from "../sprint/sprintView";
import Movement from "../../interaction/Movement";
import {cleanThemes, fetchThemes} from "model/themes/themes.actions";
import {cleanUserRoles, fetchUserRoles} from "model/userRoles/userRoles.actions";
import {cleanStates, fetchStates} from "model/states/states.actions";
import {cleanSprints, fetchSprints} from "model/sprints/sprints.actions";
import {cleanCollaborators, fetchCollaborators} from "model/collaborators/collaborators.actions";
import {connect} from "react-redux";
import Loader from "../../Loader";
import {cleanGeneralSettings, fetchGeneralSettings} from "model/generalProjectSettings/generalProjectSettings.actions";
import WsServerConnector from "helpers/wsServerConnector"
import constants from '../../../constants/index'
import Filtering from "../../filtering/Filtering.jsx";
import Sidebar from "react-sidebar";
import './ProductBacklogView.css'
import {cleanFilterCriteria} from "model/filtering/filterCriteria.action";

class ProductBacklogView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            modalMode: 'create',
            sidebarOpen: false
        }
    }

    closeUserStoryModal() {
        this.setState({...this.state, show: false})
        store.dispatch(setCurrentUserStory({}))
    }

    openUserStoryModalEditMode() {
        this.setState({...this.state, show: true, modalMode: 'edit'})
    }

    openUserStoryModalCreateMode() {
        this.setState({...this.state, show: true, modalMode: 'create'})
    }

    render() {
        return (
            <div id={"product-backlog-view"}>
                <Sidebar
                    sidebar={this.state.sidebarOpen === true &&
                    <Filtering toggleFilter={() => this.setState({sidebarOpen: !this.state.sidebarOpen})} projectId={this.props.match.params.projectId}/>}
                    open={this.state.sidebarOpen}
                    onSetOpen={() => this.setState({sidebarOpen: !this.state.sidebarOpen})}
                    styles={{
                        sidebar: {
                            background: "white",
                            zIndex:10,
                            width:"30vw",
                            position:"fixed",
                        },
                        overlay:{
                            zIndex:9
                        }
                    }}
                    pullRight={true}
                    shadow={true}
                    touch={true}
                />

                    {this.props.location.state.typeOfProject === 'invited' &&
                        <NavBar account={true}
                                projects={true}
                                projectsSettings={false}
                                filtering={true}
                                openFilter={() => this.setState({sidebarOpen: !this.state.sidebarOpen})}
                        />
                    }

                    {(this.props.location.state.typeOfProject === 'own' || this.props.location.state === 'backToBacklog') &&
                        <NavBar account={true}
                                projects={true}
                                projectsSettings={true}
                                filtering={true}
                                openFilter={() => this.setState({sidebarOpen: !this.state.sidebarOpen})}/>
                        }

                    <ProjectHeader projectName={this.props.generalSettings.name}
                                   connected={this.props.connectedToWsServer}
                                   online={this.props.amntPeopleOnline}/>

                    <ModalUS show={this.state.show}
                             onHide={() => this.closeUserStoryModal()}
                             modalMode={this.state.modalMode}/>

                    {(this.props.userStories.pending || this.props.sprints.pending || this.props.states.pending) &&
                        <div className="product-backlog-view">
                            <Loader/>
                        </div>}
                        {!(this.props.userStories.pending || this.props.states.pending || this.props.sprints.pending) &&
                        <>
                            <ProductBacklog
                                openModalCreate={this.openUserStoryModalCreateMode.bind(this)}
                                openModalEdit={this.openUserStoryModalEditMode.bind(this)}
                                projectId={this.props.match.params.projectId}
                            />
                            <div className={"allZones"}>
                                <SprintView
                                    states={this.props.states.statesList}
                                    sprints={this.props.sprints.sprintsList}
                                    openModalEdit={this.openUserStoryModalEditMode.bind(this)}
                                    projectId={this.props.match.params.projectId}
                                />
                            </div>
                            <Movement/>
                        </>
                    }
            </div>
        );

    }

    /**
     * Fetch the userstories on mount of this component
     */
    componentDidMount() {
        const {
            fetchUserStories,
            fetchThemes,
            fetchUserRoles,
            fetchStates,
            fetchSprints,
            fetchGeneralSettings,
            fetchCollaborators
        } = this.props;
        fetchUserStories();
        fetchThemes();
        fetchUserRoles();
        fetchStates();
        fetchSprints();
        fetchGeneralSettings();
        fetchCollaborators();

        this.ws = new WsServerConnector(constants().websocketUrl + '/?project=' + this.props.match.params.projectId, this.props.match.params.projectId)
    }

    componentWillUnmount() {
        const {
            cleanThemes,
            cleanUserRoles,
            cleanStates,
            cleanSprints,
            cleanGeneralSettings,
            cleanCollaborators,
            cleanFilterCriteria
        } = this.props;
        cleanThemes()
        cleanUserRoles()
        cleanStates()
        cleanSprints()
        cleanGeneralSettings()
        cleanCollaborators()
        cleanFilterCriteria()

        this.ws.closeConnection()
    }
}

/**
 * connects the needed data from the store to the component
 * @param state
 * @returns {{userStories: []}}
 */
const mapStateToProps = state => {
    return {
        userStories: state.projects.current.userStories,
        themes: state.projects.current.themes,
        userRoles: state.projects.current.userRoles,
        states: state.projects.current.states,
        sprints: state.projects.current.sprints,
        generalSettings: state.projects.current.generalProjectSettings.generalProjectSettings,
        connectedToWsServer: state.websocket.connected,
        amntPeopleOnline: state.websocket.social.online
    }
}

const mapDispatchToProps = (dispatch, state) => {
    return {
        fetchUserStories: () => dispatch(fetchUserStories(state.match.params.projectId)),
        fetchThemes: () => dispatch(fetchThemes(state.match.params.projectId)),
        fetchUserRoles: () => dispatch(fetchUserRoles(state.match.params.projectId)),
        fetchStates: () => dispatch(fetchStates(state.match.params.projectId)),
        fetchSprints: () => dispatch(fetchSprints(state.match.params.projectId)),
        fetchGeneralSettings: () => dispatch(fetchGeneralSettings(state.match.params.projectId)),
        fetchCollaborators: () => dispatch(fetchCollaborators(state.match.params.projectId)),
        cleanThemes: () => dispatch(cleanThemes()),
        cleanUserRoles: () => dispatch(cleanUserRoles()),
        cleanStates: () => dispatch(cleanStates()),
        cleanSprints: () => dispatch(cleanSprints()),
        cleanGeneralSettings: () => dispatch(cleanGeneralSettings()),
        cleanCollaborators: () => dispatch(cleanCollaborators()),
        cleanFilterCriteria: () => dispatch(cleanFilterCriteria())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBacklogView)