import React from 'react';
import logo_Registration from "../../assets/Logo_PA_400x400.svg";
import {Field, reduxForm} from "redux-form";
import FieldInput from "../form/fieldInput/FieldInput";
import Button from "components/form/buttons/SubmitButton";
import {Link} from "react-router-dom";
import './Registration.css'
import store from "../../store";
import {sendUserRegistration} from "model/user/user.action";
import {
    email,
    maxLength100,
    minLength3,
    minLength8,
    password,
    passwordsMatch,
    required
} from "validation/InputValidation";
import {Spinner} from "react-bootstrap";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
    loading: state.user.pending,
    response: state.user.error
});

let Registration = ({handleSubmit, ...props}) => {
    return (
        <div className={'container-registration'}>
            <div id={'row1'}>
                <h1 className={'StoryScreenTitle'}>StoryScreen</h1>
                <img id={'imageRegistration'} src={logo_Registration} alt="Logo_PA_400x400"/>
            </div>
            <div id={'row2'}>
                <h1 className={'registrationTitle'}>Registration</h1>
                <div>
                    <form onSubmit={handleSubmit}>
                        <Field id={'firstname'}
                               name="firstname"
                               label="Firstname"
                               type="text"
                               fieldType='registration'
                               component={FieldInput}
                               placeholder="Firstname"
                               aria-label="Recipient's username"
                               aria-describedby="emailHelp"
                               validate={[required, minLength3, maxLength100]}
                        />
                        <Field id={'lastname'}
                               name="lastname"
                               label="Lastname"
                               type="text"
                               fieldType='registration'
                               component={FieldInput}
                               placeholder="Lastname"
                               aria-label="Recipient's username"
                               aria-describedby="emailHelp"
                               validate={[required, minLength3, maxLength100]}
                        />
                        <Field id={'email'}
                               name="email"
                               label="E-Mail"
                               type="email"
                               fieldType='registration'
                               component={FieldInput}
                               placeholder="example@gmail.com"
                               aria-label="Recipient's username"
                               aria-describedby="emailHelp"
                               validate={[required, email, maxLength100]}
                        />
                        <Field id={'password'}
                               name="password"
                               label="Password"
                               type="password"
                               fieldType='registration'
                               component={FieldInput}
                               placeholder="Password"
                               aria-label="Recipient's username"
                               aria-describedby="basic-addon2"
                               validate={[required, minLength8, maxLength100, password]}
                        />
                        <Field id={'confirmPassword'}
                               name="confirmPassword"
                               label="Confirm Password"
                               type="password"
                               fieldType='registration'
                               component={FieldInput}
                               placeholder="Confirm password"
                               aria-label="Recipient's username"
                               aria-describedby="basic-addon2"
                               validate={[required, passwordsMatch]}
                        />
                        <div className={'buttonRegistration'}>
                            <Button
                                type="submit"
                                styleClass="btn-primary-custom-btn-submit"
                                name={'Register'}
                            /></div>
                    </form>
                    {props.loading &&
                    <Spinner animation="grow"/>}
                    {props.response &&
                    <p id={'errorMessage'}>The registration could not be completed. Try again later.</p>}
                </div>
                <p className={'registrationText'}>Already have an account? <Link className={'registrationLink'}
                                                                                 to={'/'}> Sign In</Link>
                </p>
            </div>
        </div>
    )
}

const onSubmit = values => {
    store.dispatch(sendUserRegistration(values))
}

Registration = reduxForm({
    form: 'registration',
    onSubmit
})(Registration)

export default connect(mapStateToProps)(Registration)