import React, {useEffect} from "react"
import {connect} from "react-redux";

import FormCard from "components/form/formCard/FormCard";
import ListGroup from "react-bootstrap/ListGroup";
import StateForm from "components/project/projectSettings/projectSettingsCards/stateSettingsCard/StateForm";
import {PROJECT_SETTINGS_STATE_CREATE, PROJECT_SETTINGS_STATE_EDIT} from "constants/index";
import {cleanStates, fetchStates} from "model/states/states.actions";

let projectId;

function StateSettingsCard({handleSubmit, mode, ...props}) {
    projectId = props.projectId

    useEffect(() => {
        props.fetchStates(projectId)
        return props.cleanStates
    }, [])

    return (
        <FormCard title="States" width="30vw" loading={props.loading} error={props.err}>
            <ListGroup class="list-group list-group-flush">
                {props.states.map((state) => {
                    return (
                        <ListGroup.Item key={state.stateId}>
                            <StateForm form={"stateForm" + state.stateId} mode={PROJECT_SETTINGS_STATE_EDIT}
                                       initialValues={state} projectId={projectId}/>
                        </ListGroup.Item>
                    )
                })}
                <ListGroup.Item className="newSprint" key={99999}>
                    <StateForm form="newState" mode={PROJECT_SETTINGS_STATE_CREATE} projectId={projectId}/>
                </ListGroup.Item>
            </ListGroup>
        </FormCard>
    )
}

const mapStateToProps = state => {
    return {
        states: state.projects.current.states.statesList,
        loading: state.projects.current.states.pending,
        err: state.projects.current.states.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchStates: (projectId) => dispatch(fetchStates(projectId)),
        cleanStates: () => dispatch(cleanStates())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateSettingsCard)