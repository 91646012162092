import {
    ADD_USERROLES_ERROR,
    ADD_USERROLES_PENDING,
    ADD_USERROLES_SUCCESS,
    CLEAN_CURRENT_USERROLES_PROJECT,
    DEL_USERROLES_ERROR,
    DEL_USERROLES_PENDING,
    DEL_USERROLES_SUCCESS,
    GET_USERROLES_ERROR,
    GET_USERROLES_PENDING,
    GET_USERROLES_SUCCESS,
    UPDATE_USERROLES_ERROR,
    UPDATE_USERROLES_PENDING,
    UPDATE_USERROLES_SUCCESS
} from './userRoles.types';


const INITIAL_STATE = {
    userRolesList: [],
    pending: false,
    current: null,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAN_CURRENT_USERROLES_PROJECT:
            return {
                ...state,
                userRolesList: [],
                pending: true,
                error: null
            }
        case GET_USERROLES_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case GET_USERROLES_SUCCESS:
            return {
                ...state,
                pending: false,
                userRolesList: action.payload.userRoles
            };
        case GET_USERROLES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case ADD_USERROLES_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            };
        case ADD_USERROLES_SUCCESS:
            return {
                ...state,
                pending: false,
                userRolesList: [...state.userRolesList, action.payload]
            };
        case ADD_USERROLES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            };
        case DEL_USERROLES_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case DEL_USERROLES_SUCCESS:
            return {
                ...state,
                pending: false,
                userRolesList: state.userRolesList.filter(state =>
                    state.userRoleId !== action.payload.userRoleId
                )
            };
        case DEL_USERROLES_ERROR:
            return {
                ...state,
                pending: false,
                error: (action.payload.response.data.message && action.payload.response.data) ? action.payload.response.data.message : action.payload.message
            };
        case UPDATE_USERROLES_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case UPDATE_USERROLES_SUCCESS:
            return {
                ...state,
                pending: false,
                userRolesList: state.userRolesList.map((item) => {
                    if (item.userRoleId === action.payload.userRole.userRoleId) {
                        return action.payload.userRole
                    }
                    return item
                })
            };
        case UPDATE_USERROLES_ERROR:
            return {
                ...state,
                pending: false,
                error: (action.payload.response.data.message && action.payload.response.data) ? action.payload.response.data.message : action.payload.message
            };
        default:
            return state;
    }
};

export default reducer;