import axios from "axios";
import constants, {USER_LOCAL_STORAGE} from "../../constants";
import {
    ADD_PROJECT_ERROR,
    ADD_PROJECT_PENDING,
    ADD_PROJECT_SUCCESS,
    CLEAN_CURRENT_PROJECT,
    DEL_PROJECT_ERROR,
    DEL_PROJECT_PENDING,
    DEL_PROJECT_SUCCESS,
    GET_PROJECT_BY_ID_ERROR,
    GET_PROJECT_BY_ID_PENDING,
    GET_PROJECT_BY_ID_SUCCESS,
    GET_USERPROJECTS_ERROR,
    GET_USERPROJECTS_PENDING,
    GET_USERPROJECTS_SUCCESS,
} from "./project.types";
import {authHeader} from "helpers/auth-header";
import DateFormatter from "helpers/dateFormatter";
import history from "helpers/history";

export const fetchProjectWithId = (projectId) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchProjectWithIdRequest());
        let userProjectPromise = axios.get(constants().apiUrl + '/project/' + projectId, requestConfig)
        userProjectPromise.then(
            res => {
                dispatch(fetchProjectWithIdSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(fetchProjectWithIdError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchProjectWithIdRequest = () => ({
    type: GET_PROJECT_BY_ID_PENDING
})

export const fetchProjectWithIdSuccess = project => ({
    type: GET_PROJECT_BY_ID_SUCCESS,
    payload: project
})

export const fetchProjectWithIdError = err => ({
    type: GET_PROJECT_BY_ID_ERROR,
    payload: err
})

export const addProject = (values) => {
    const requestConfig = {
        headers: authHeader()
    };

    let valuesNew = {
        ...values,
        startDate: DateFormatter(values.startDate)
    }
    delete valuesNew.creator

    return (dispatch) => {
        dispatch(addProjectRequest());
        let userProjectPromise = axios.post(constants().apiUrl + '/project', valuesNew, requestConfig)
        userProjectPromise.then(
            res => {
                history.push('/project')
                dispatch(addProjectSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(addProjectError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const addProjectRequest = () => ({
    type: ADD_PROJECT_PENDING
})

export const addProjectSuccess = (res) => ({
    type: ADD_PROJECT_SUCCESS,
    payload: res.project
})

export const addProjectError = (err) => ({
    type: ADD_PROJECT_ERROR,
    payload: err
})

export const deleteProject = (path) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(deleteProjectPending());
        let projectPromise = axios.delete(constants().apiUrl + path, requestConfig)
        projectPromise.then(
            res => {
                dispatch(deleteProjectSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(deleteProjectError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const deleteProjectPending = project => ({
    type: DEL_PROJECT_PENDING,
    payload: project
});

export const deleteProjectSuccess = project => ({
    type: DEL_PROJECT_SUCCESS,
    payload: project
});

export const deleteProjectError = err => ({
    type: DEL_PROJECT_ERROR,
    payload: err
});

export const cleanCurrentProject = () => ({
    type: CLEAN_CURRENT_PROJECT
})


export const fetchUserProjects = () => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchUserProjectsRequest());
        let userProjectPromise = axios.get(constants().apiUrl + '/user/' + JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE)).userId + '/project', requestConfig)
        userProjectPromise.then(
            res => {
                dispatch(getAllUserProjectsSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(getAllUserProjectsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchUserProjectsRequest = () => ({
    type: GET_USERPROJECTS_PENDING,
});

export const getAllUserProjectsSuccess = projects => ({
    type: GET_USERPROJECTS_SUCCESS,
    payload: {projects}
});

export const getAllUserProjectsError = err => ({
    type: GET_USERPROJECTS_ERROR,
    payload: {err}
});
