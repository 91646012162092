import interact from 'interactjs'
import {updateUserStoryRequest} from "model/userStories/userStories.actions";
import store from "store";

export const InitDropZone = (dropZoneId, stateId, sprintId, projectId) => {
    interact('#' + dropZoneId).dropzone({
        type: "drop",
        accept: '.usCard', // only accept elements matching this CSS selector
        overlap: 'center',
        ondropactivate: function (event) {
            // add active dropzone feedback
            event.target.classList.add('drop-active')
        },
        ondragenter: function (event) {
            const dropzoneElement = event.target;

            // feedback the possibility of a drop
            dropzoneElement.classList.add('drop-target')

            const dropRect = interact.getElementRect(event.target),
                dropCenter = {
                    x: dropRect.left + dropRect.width / 2,
                    y: dropRect.top + dropRect.height / 2
                };
            event.draggable.draggable({
                snap: {
                    targets: [dropCenter]
                }
            });
        },
        ondragleave: function (event) {
            event.target.classList.remove('drop-target')
            event.relatedTarget.classList.remove('can-drop')
        },
        ondrop: function (event) {
            event.stopImmediatePropagation()
            const userStoryId = event.relatedTarget.id;
            store.dispatch(updateUserStoryRequest(userStoryId, stateId, sprintId, projectId));

            event.relatedTarget.classList.remove('drag-start');

            if (!store.getState().projects.current.userStories.error)
                event.relatedTarget.style.display = 'none';

            new Promise((resolve) => {
                setTimeout(() => {
                    resolve()
                }, 2000);
            }).then(() => {
                event.relatedTarget.style.display = 'block';
            })
        },
        ondropdeactivate: function (event) {
            // remove active dropzone feedback
            event.target.classList.remove('drop-active')
            event.target.classList.remove('drop-target')
        }
    })
}