import React from 'react';
import './CheckboxInput.css'

function CheckboxInput({fieldType, required, input, item, prepend, meta: {touched, error}, label, ...props}) {

    return (
        <div className={'spaceLabel'}>
            <label>
                <input
                    className={'checkBoxSpace'}
                    checked={
                        input.value.indexOf(item.label) !== -1
                    }
                    onChange={event => {
                        const newValue = [...input.value];
                        if (event.target.checked) {
                            newValue.push(item.label);
                        } else {
                            newValue.splice(newValue.indexOf(item.label), 1);
                        }
                        return input.onChange(newValue);
                    }}
                    onFocus={input.onFocus}
                    onDrop={input.onDrop}
                    onDragStart={input.onDragStart}
                    {...props}
                />
                {item.label}
            </label>
        </div>
    )
}

export default CheckboxInput;