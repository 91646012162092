import React, {useEffect} from 'react';
import NavBar from "../navigation/NavBar";
import './AccountSettings.css'
import {Field, reduxForm} from "redux-form";
import FieldInput from "../form/fieldInput/FieldInput";
import {fetchUser, updateUserData} from "../../model/user/user.action";
import store from "../../store";
import {connect} from "react-redux";
import {email, maxLength100, minLength3, required} from "../../validation/InputValidation";
import Avatar from "./Avatar";
import Button from 'components/form/buttons/SubmitButton';
import {USER_LOCAL_STORAGE} from "../../constants";

const mapStateToProps = state => {
    return {
        initialValues: state.user.user,
    }
}

let AccountSettings = ({handleSubmit, ...props}) => {
    useEffect(() => {
        store.dispatch(fetchUser());
    }, [props.initialValues]);
    const userObject = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE));
    return (
        <>
            <NavBar account={false} projects={true}/>
            <div id={'container-account'}>
                <div id={'columnAvatar'}>
                    <div id={'avatar'}>
                        <Avatar name={userObject.firstname.charAt(0) + userObject.lastname.charAt(0)} size={'20vw'}
                                fontsize={'10vw'} border={'#989797 solid 5px'}/>
                    </div>
                </div>
                <div id={'columnForm'}>
                    <form onSubmit={handleSubmit}>
                        <h1 className={'accountTitle'}>Account Settings</h1>
                        <Field id={'firstname'}
                               name="firstname"
                               label="Firstname"
                               type="text"
                               fieldType='account'
                               component={FieldInput}
                               aria-label="Recipient's username"
                               aria-describedby="emailHelp"
                               validate={[required, minLength3, maxLength100]}
                        />
                        <Field id={'lastname'}
                               name="lastname"
                               label="Lastname"
                               type="text"
                               fieldType='account'
                               component={FieldInput}
                               aria-label="Recipient's username"
                               aria-describedby="emailHelp"
                               validate={[required, minLength3, maxLength100]}
                        />
                        <Field id={'email'}
                               name="email"
                               label="E-Mail"
                               type="email"
                               fieldType='account'
                               component={FieldInput}
                               aria-label="Recipient's username"
                               aria-describedby="emailHelp"
                               validate={[required, email, maxLength100]}
                        />
                        <div className={'buttonAccountSettings'}>
                            <Button
                                type="submit"
                                styleClass="btn-primary-custom-btn-submit"
                                name={'Update'}
                            />
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}

const onSubmit = values => {
    store.dispatch(updateUserData(values))
}

AccountSettings = reduxForm({
    form: 'account',
    onSubmit,
    enableReinitialize: true
})(AccountSettings)


export default connect(mapStateToProps)(AccountSettings)