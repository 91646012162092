function DateFormatter(dateAndTime) {
    // Format 2021-03-29T22:00:00.000Z
    if (!dateAndTime) {
        return null
    }
    if (typeof dateAndTime === "string") {
        const date = dateAndTime.split('T')
        const dateNumbers = date[0].split('-')
        if (dateNumbers.length < 3) {
            return dateAndTime
        }
        const newOrder = dateNumbers[2] + '.' + dateNumbers[1] + '.' + dateNumbers[0]
        return newOrder.replaceAll(' ', '.')
    }

    let day, month
    day = dateAndTime.getDate();
    month = dateAndTime.getMonth() + 1;
    (day < 10) ? day = "0" + day : day = day;
    (month < 10) ? month = "0" + month : month = month;

    return day + "." + month + "." + dateAndTime.getFullYear();
}

export default DateFormatter