import {
    CLEAN_CURRENT_GENERAL_SETTINGS_PROJECT,
    GET_GENERAL_ERROR,
    GET_GENERAL_PENDING,
    GET_GENERAL_SUCCESS,
    UPDATE_GENERAL_ERROR,
    UPDATE_GENERAL_PENDING,
    UPDATE_GENERAL_SUCCESS
} from 'model/generalProjectSettings/generalProjectSettings.types';

const INITIAL_STATE = {
    generalProjectSettings: {
        name: null,
        description: null,
        definitionOfDone: null,
        startDate: null,
        creator: null
    },
    pending: false,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAN_CURRENT_GENERAL_SETTINGS_PROJECT:
            return {
                ...state,
                generalProjectSettings: {
                    name: null,
                    startDate: null,
                    description: null,
                    definitionOfDone: null,
                    creator: null
                },
                error: null,
                pending: false
            }
        case UPDATE_GENERAL_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            }
        case UPDATE_GENERAL_SUCCESS:
            return {
                ...state,
                generalProjectSettings: {
                    name: action.payload.name,
                    startDate: action.payload.startDate,
                    description: action.payload.description,
                    definitionOfDone: action.payload.definitionOfDone,
                    creator: action.payload.creator
                },
                pending: false
            }
        case UPDATE_GENERAL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            }
        case GET_GENERAL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.message
            }
        case GET_GENERAL_PENDING:
            return {
                ...state,
                pending: true,
                error: null
            }
        case GET_GENERAL_SUCCESS:
            return {
                ...state,
                generalProjectSettings: {
                    name: action.payload.name,
                    startDate: action.payload.startDate,
                    description: action.payload.description,
                    definitionOfDone: action.payload.definitionOfDone,
                    creator: action.payload.creator
                },
                pending: false
            }
        default:
            return state;
    }
};

export default reducer;